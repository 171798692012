import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { merchantEnv, getRuntimeConfig, setRunTimeConfig } from '@portal/api-endpoints';

getRuntimeConfig()
  .then(async config => {
    setRunTimeConfig(config, merchantEnv.endpoints);

    if (merchantEnv.production) {
      enableProdMode();
    }

    await import('./app/app.module').then(m => platformBrowserDynamic().bootstrapModule(m.AppModule));
  })
  .catch(err => {
    console.error(err);
  });
